import React, {useState, useEffect} from 'react'
//import AuthService from '../../services/AuthService'
import ForumService from '../../services/ForumService'
import validate from '../../utils/validate'
import Input from '../ui/Input'
//import { Link } from 'react-router-dom';

const TopicForm = (props) => {
  //const authService = new AuthenticationService()
  //const date = moment(new Date()).format('YYYY-MM-DD h:mm:ss a')
  //const [state] = useContext(Context)
  const [topic, setTopic] = useState({
    id: props.match.params.id,
		title: '',
		description: '',
		createdBy: {id: 1},
		lastPostBy: {id: 1},
    active: true
  })
  const [error, setError] = useState(null)
  const [pageValid, setPageValid] = useState(true)
        
  // ----------------------- VALIDATION RULES -----------------------
  const [titleValid, setTitleValid] = useState({
    isValid: true,
    rules: {
      required: true,
      minLength: 4
    }
  })
  const [descriptionValid, setContentValid] = useState({
    isValid: true,
    rules: {
      required: true,
      minLength: 4
    }
  })
  // ----------------------- VALIDATION RULES, END -----------------------

	useEffect(() => {      
    if(topic.id !== "new") fetchTopic()
  },[topic.id])
	
  const fetchTopic = () => { 
    ForumService.getTopicById(topic.id)
    .then(response => {
      setTopic(response.data)
    })
    .catch(e => { // CONSIDER: forward to error page, or return error div
      let fetchError = e.message || e.response.data
      console.log(fetchError)
      setError(<h3 style={{position: 'fixed', top: '62px'}}>{fetchError}</h3>)  //TODO: parameterize style
    })    
  }

  const deleteTopicClicked = () => { // TODO: verify logged in
    ForumService.deleteTopic(topic.id)
    .then(response => {
      console.log(`Piss off topic ${topic.id}! He's frickin gone.`, response)
      props.history.push('/forum')
    })
  }

  const onSubmit = (event) => {
    event.preventDefault()

    if(!pageValid) return

    // url passes -1 for a new topic, so any update will be > 0 id
    // the backend updates modified date on an update so that the datetime is as close to real modified time to the second as possible
    ForumService.createOrUpdateTopic(topic.id, {...topic, createdDate: null, lastPostDate: null})
    .then(response => {
      console.log(response.headers['newid'])
      setTimeout(function(){ props.history.push("/forum") }, 1000);
    }, (error) => {
      console.log(error)
    })
  }

	// repeated method, TODO: move to js file
  const inputChange = (event, setter, obj, alteration, rule, ruleSetter) => {
		const isValid = validate(event.target.value, rule)
    setter({ ...obj, ...alteration })
    if(rule && ruleSetter) {
      ruleSetter({ ...rule, isValid })
		}
		setPageValid(isValid)
  }
//top:
  return !error ? (
    <>
      <h1 className='container double-header'>{topic.id !== "new" ? 'Edit ' : 'New '}Topic</h1> 
      <div className="container">
        <form onSubmit={onSubmit}>
          <Input elementType="input" name="title" value={topic.title} label="Title" isValid={titleValid.isValid} show={true}
            changed={(event) => {
              inputChange(event, setTopic, topic, { title: event.target.value }, titleValid, setTitleValid)
            }}
          />
          <Input elementType="textarea" name="description" value={topic.description} label="Topic" isValid={descriptionValid.isValid} show={true}
            changed={(event) => {
              inputChange(event, setTopic, topic, { description: event.target.value }, descriptionValid, setContentValid)
            }}
          />
          <Input elementType="checkbox" name="active" value={topic.active} checked={topic.active} label="Active" show={true}
            changed={() => {
              setTopic({ ...topic, active: !topic.active })
            }}
          />
        <input className="btn btn-primary" type="submit" value="Save" />
        </form>
        <button
          className="btn-over btn btn-danger float-right delete d-none"
          onClick={() => { if (window.confirm('Delete topic? This kick is permanent.')) deleteTopicClicked(topic.id) } }
        ><small>Delete this topic</small></button>
      </div>
    </>
  ) : error
}

export default TopicForm