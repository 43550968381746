import AdventureButton from './ui/AdventureButton'

const Secret = () => {

	return (
		<>
			<div className="bg-city bg-image" style={{height: '951px'}}>
				<AdventureButton/>
			</div>
			<footer>
				<a href="https://www.freepik.com/free-photo/two-travelers-discovered-ruins-ancient-city-dense-forest-3d-illustration_15174550.htm#query=fantasy%20city&position=2&from_view=search&track=ais&uuid=2ca3bdc4-eed3-4ca3-aa6a-52669f7e0d5c">Image by liuzishan</a>
			</footer>
		</>
	)
}

export default Secret