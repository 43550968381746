import React, {useState, useEffect} from 'react'
import HeaderForum from '../ui/HeaderForum'

import PostService from '../../services/PostService'
import PostRow from './PostRow'
import TopicRow from './TopicRow'

const PostList = (props) => {
	const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false)
  const { state } = props.location;
  let postList = []
  // let recentX = 10

  //TODO: this is looping 
  useEffect(() => {
    if(!posts.length) loadPosts(state.topic.id)
  },[])


  const loadPosts = (topicId) => {
    PostService.getPostsByTopic(topicId)
    .then(response => {
      setPosts(response.data)
      setLoading(false)
    })
    .catch(e => {
      let fetchError = e.message || e.response.data
      console.log(e)
      setError(
        <div>
          <HeaderForum/>
          <h3 style={{position: 'fixed', top: '62px'}}>{fetchError}</h3>
        </div>
      )
    })
  }

  if(posts.length) {
    postList = (
      posts.map(post => (
        <PostRow post={post} key={post.id} />
      ))
    )
  }
  
  let waitContent = error ? error : 
  <div>
    <HeaderForum/>
    <h3 style={{position: 'fixed', top: '62px'}}>Loading...</h3>
  </div>


// TODO: ?? can the topic be the header??
	return (error || loading) ? waitContent : (
		<>
			<HeaderForum/>
        <div className='container double-header'>
          <div className="container list-group-item">
            <div className='list-group'>
              <TopicRow topic={state.topic} key={state.topic.id} />
            </div>
          </div>
				<div className='list-group'>
      		{postList}
				</div>
			</div>
		</>
  )
}

export default PostList