import React, {useState} from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import ForumService from '../../services/ForumService'

// TODO: admin delete button
const TopicRow = (props) => {
	let { title, description, createdByFirstName, createdByLastName, createdDate, lastPostByFirstName, lastPostByLastName, lastPostDate, replies, views, active } = props.topic
	const [error, setError] = useState(false)
	const isAdmin = false

	const deletePost = (id) => {
		ForumService.deleteTopic(id)
		.then(response => {
			console.log(response.data || response);
			window.location.reload(true);	// page reload, true = new server call
		})
		.catch(e => {
			let fetchError = e.message || e.response.data
			console.log(fetchError)
			if(e.response.status > 400) {
				window.alert(e.message + ' ' + e.response.data.error)
			}
		})
	}

	const actionButton = isAdmin ? 
		<button type="button" className="btn btn-danger btn-sm"
			onClick={() => {if (window.confirm("Delete the item?")) deletePost(props.topic.id) } }
		>Delete</button>
	:	<Link to={`/post-form/topic/${props.topic.id}`}>
			<button type="button" class="btn btn-success btn-sm p-0">Comment</button>
		</Link>


	return (
		
		<div className="container list-row list-group-item list-group-item-action flex-column align-items-start active border border-dark rounded-lg my-1" style={{ display: active ? 'inline':'none' }}>
			<div className='row'>
				<Link to={{
					pathname: `/post-list/${props.topic.id}`,
					state: props
				}} className='col-3' style={{ color: "inherit", textDecoration: "none" }}>
					<h6 className="mb-1 font-weight-bold">{title}</h6>
					<small>Creator, {createdByFirstName} {createdByLastName}<br/>{createdDate}</small>
				</Link>
				<Link to={{
					pathname: `/post-list/${props.topic.id}`,
					state: props
				}} className='col-5' style={{ color: "inherit", textDecoration: "none" }}>
					<p className="mb-1">{description}</p>
				</Link>
				<div className='col-2'>
					<small>{lastPostByFirstName} {lastPostByLastName}<br/>{lastPostDate}</small>
				</div>
				<div className='col-1'>
					<small>posts: {replies}<br/>views: {views}</small>
				</div>
				<div className='col-1'>
					{actionButton}
				</div>
			</div>
		</div>
		
	)
}

export default TopicRow