import React, { useState } from 'react'

const Teasers = () => {
	const [mapTile, setMapTile] = useState({
		id: 1,
		posX: 50,
		posY: 50,
		mapId: 1,
		northDoor: true,
		southDoor: true,
		eastDoor: true,
		westDoor: true,
		trap: false,
		treasure: false,
		monster: false
	})

	return (
		<>
			<div className='bg-image'>
				<h1>👊</h1>
			</div>
		</>		
	)
}

export default Teasers