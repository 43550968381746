import React, {useState, useEffect} from 'react'
//import AuthService from '../../services/AuthService'
import PostService from '../../services/PostService'
import validate from '../../utils/validate'
import Input from '../ui/Input'
//import { Link } from 'react-router-dom';


// TODO: logic for Reply/associate to parent post and same topic, vs New/associate to topic/parent = 0
const PostForm = (props) => {
  const [post, setPost] = useState({
    id: props.match.params.id,
		title: '',
		description: '',
		createdBy: {id: 1},
    active: true
  })
  const [error, setError] = useState(null)
  const [pageValid, setPageValid] = useState(true)
        
  // ----------------------- VALIDATION RULES -----------------------
  const [titleValid, setTitleValid] = useState({
    isValid: true,
    rules: {
      required: true,
      minLength: 4
    }
  })
  const [descriptionValid, setContentValid] = useState({
    isValid: true,
    rules: {
      required: true,
      minLength: 4
    }
  })
  // ----------------------- VALIDATION RULES, END -----------------------

	useEffect(() => {      
    if(post.id !== "new") fetchPost()
  },[post.id])
	
  const fetchPost = () => { 
    PostService.getPostById(post.id)
    .then(response => {
      setPost(response.data)
    })
    .catch(e => { // CONSIDER: forward to error page, or return error div
      let fetchError = e.message || e.response.data
      console.log(fetchError)
      setError(<h3 style={{position: 'fixed', top: '62px'}}>{fetchError}</h3>)  //TODO: parameterize style
    })    
  }

  const deletePostClicked = () => { // TODO: verify logged in
    PostService.deletePost(post.id)
    .then(response => {
      console.log(`Piss off post ${post.id}! He's frickin gone.`, response)
      props.history.push('/forum')
    })
    .catch(e => {
      let fetchError = e.message || e.response.data
      console.log('hQWERWERTYDFGBSEB!!ere:' + e)
      alert("You are not authorized");
    })
  }

  const onSubmit = (event) => {
    event.preventDefault()

    if(!pageValid) return

    // url passes -1 for a new post, so any update will be > 0 id
    // the backend updates modified date on an update so that the datetime is as close to real modified time to the second as possible
    PostService.createOrUpdatePost(post.id, {...post, createdDate: null, lastPostDate: null})
    .then(response => {
      console.log(response.headers['newid'])
      setTimeout(function(){ props.history.push("/forum") }, 1000);
    }, (error) => {
      console.log(error)
    })
  }

	// repeated method, TODO: move to js file
  const inputChange = (event, setter, obj, alteration, rule, ruleSetter) => {
		const isValid = validate(event.target.value, rule)
    setter({ ...obj, ...alteration })
    if(rule && ruleSetter) {
      ruleSetter({ ...rule, isValid })
		}
		setPageValid(isValid)
  }

//TODO: create logic that sticky's title when replying, and increment Replies. Created parent.
  return !error ? (
    <>
      <h1 className='container double-header'>{post.id !== "new" ? 'Edit ' : 'New '}Post</h1> 
      <div className="container">
        <form onSubmit={onSubmit}>
          <Input elementType="input" name="title" value={post.title} label="Title" isValid={titleValid.isValid} show={true}
            changed={(event) => {
              inputChange(event, setPost, post, { title: event.target.value }, titleValid, setTitleValid)
            }}
          />
          <Input elementType="textarea" name="description" value={post.description} label="Post" isValid={descriptionValid.isValid} show={true}
            changed={(event) => {
              inputChange(event, setPost, post, { description: event.target.value }, descriptionValid, setContentValid)
            }}
          />
          <Input elementType="checkbox" name="active" value={post.active} checked={post.active} label="Active" show={true}
            changed={() => {
              setPost({ ...post, active: !post.active })
            }}
          />
        <input className="btn btn-primary" type="submit" value="Save" />
        </form>
        <button
          className="btn-over btn btn-danger float-right delete d-none"
          onClick={() => { if (window.confirm('Delete post? This kick is permanent.')) deletePostClicked(post.id) } }
        ><small>Delete this post</small></button>
      </div>
    </>
  ) : error
}

export default PostForm