import React, {useState} from 'react'
import { Redirect } from "react-router-dom";


const AdventureButton = () => {

  const [props, setProps] = useState({
    buttonTop: 7,
    buttonLeft: 0,
    burronBorderWidth: 0,
    boxShadowEnabled: false,
    count: 0
  })

  const generateRandomPotition = () => {
    const newButtonTop = Math.floor(Math.random() * 88) + 7;
    const newButtonLeft = Math.floor(Math.random() * 91);
    const newCount = props.count + 1;

    setProps({buttonTop: newButtonTop, buttonLeft: newButtonLeft, buttonBorderWidth: newCount * 2, boxShadowEnabled: (newCount > 3), count: newCount})
    
  }

  return (props.count > 4) ? (<Redirect to="/about"/>) : (
    <button className='adventure-button' style={{ background: props.boxShadowEnabled ? 'springgreen' : 'darkgray', position: 'fixed', zIndex: '998', top: props.buttonTop + '%', left: props.buttonLeft + '%', 
        borderColor: 'darkred', borderWidth: props.buttonBorderWidth + 'px', boxShadow: props.boxShadowEnabled ? '10px 5px 5px red' : '' }} onClick={generateRandomPotition}>
      <p className='p-0' style={{ fontStyle: (props.count > 0) ? 'italic' : 'normal' }}>Adventure Awaits</p>
      <p className='p-0'>Begin ...</p>
    </button>
  )
  
}

export default AdventureButton