import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

// TODO: admin delete button
const PostRow = (props) => {
	let { title, description, createdByFirstName, createdByLastName, createdDate, lastPostByFirstName, lastPostByLastName, lastPostDate, replies, views, active } = props.post
	return (
		<Link to={`/post-form/${props.post.id}`} className="container list-row list-group-item list-group-item-action flex-column align-items-start active border border-dark rounded-lg" style={{ display: active ? 'inline':'none' }}>
			
			<div className='row'>
				<div className='col-3'>
					<h6 className="mb-1 font-weight-bold">{title}</h6>
					<small>Creator, {createdByFirstName} {createdByLastName}<br/>{createdDate}</small>
				</div>
				<div className='col-5'>
					<p className="mb-1">{description}</p>
				</div>
				<div className='col-2'>
					<small>{lastPostByFirstName} {lastPostByLastName}<br/>{lastPostDate}</small>
				</div>
				<div className='col-1'>
					<small>posts: {replies}<br/>views: {views}</small>
				</div>
				<div className='col-1'>
					<Link to={`/post-form/${props.post.id}`}>
						<button type="button" class="btn btn-success btn-sm p-0">Reply</button>
					</Link>
				</div>
			</div>
			
		</Link>
	)
}

export default PostRow
