import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import Logo from '../Logo'

const HeaderForum = () => {

	const [isSticky, setSticky] = useState(false);
	
	// const handleScroll = () => {	
	// 	const windowScrollTop = window.scrollY;
	
	// 	if (windowScrollTop > 10) {
	// 		setSticky(true);
	// 	} else {
	// 		setSticky(false);
	// 	}
	// };
	
	// useEffect(() => {
	// 	window.addEventListener('scroll', handleScroll);
 		
	// 	return () => {
	// 		window.removeEventListener('scroll', handleScroll);
	// 	};

	// }, []);

	return (
		<header style={{ background: isSticky ? '#fff' : '', width: '100%', zIndex: '998', position:isSticky ?'fixed':'absolute', top: '62px' }}>
			<nav className="navbar navbar-expand-md navbar-dark bg-dark justify-content-between">
				<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarForum" aria-controls="navbarForum" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarForum">
					<ul className="navbar-nav mr-auto">
						<li className="nav-item">
							<NavLink className="nav-link" to="/topic-form/new">New Topic</NavLink>
						</li>         
					</ul>
					<form className="form-inline my-2 my-lg-0">
						<input className="form-control mr-sm-2" type="search" placeholder="imagine..." aria-label="Search"/>
						<button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
					</form>	
				</div>
			</nav>
		</header>
	)
}

export default HeaderForum

// import React, { useState, useEffect } from 'react';

// function StickyHeader() {
// 	const [isSticky, setSticky] = useState(false);
	
// 	const handleScroll = () => {
	
// 		const windowScrollTop = window.scrollY;
	
// 		if (windowScrollTop > 10) {
// 			setSticky(true);
// 		} else {
// 			setSticky(false);
// 		}
// 	};
	
// 	useEffect(() => {
// 		window.addEventListener('scroll', handleScroll);
 		
// 		return () => {
// 			window.removeEventListener('scroll', handleScroll);
// 		};

//  }, []);

// const items = [
// 	{
// 		name: 'Home',
// 		link: '/'
// 	},
// 	{
// 		name: 'About',
// 		link: '/about'
// 	},
// 	{
// 		name: 'Contact',
// 		link: '/contact'
// 	}
// ];

// const data = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36 ]

// return (
// 	<div className="App">
// 		<header style={{ background: isSticky ? '#fff' : '', width: '100%', zIndex: '999',position:isSticky ?'fixed':'absolute' }}>
// 			{
// 				items.map(item => (
// 				<a href={item.link} key={item.name}>{item.name}</a>
// 				))
// 			}
// 		</header>
// 		<ul>{data.map((x) => { return (<li key={x}>{x}</li>) } ) }</ul>
// 	</div>
//  );
// }
// export default StickyHeader;