import axios from 'axios'

import { FORUM_API_URL } from '../Constants'

class ForumService {
  constructor() {
    this.instance = axios.create({
      baseURL: FORUM_API_URL
    })
    
  }

  // TODO: need a for-real all for admins
  getTopicsAll() {
    return this.instance.get(`/topics`)
  }

  getTopicsByDateRange(startDate, endDate) {
    return this.instance.get(`/topics?starDate=${startDate}&endDate=${endDate}`)
  }

  getTopicsByCreatedBy(id) {
    return this.instance.get(`/topics/createdBy/${id}`)
  }

  getTopicById(id) {
    return this.instance.get(`/topics/${id}`)
  }

  deleteTopic(id) {
    return this.instance.delete(`/topics/${id}`)
  }

  createOrUpdateTopic(id, topic) {
    console.log(topic)
    if(id !== "new") {
      return this.instance.put(`/topics/${id}`, topic)
    }
    topic.id = 0
    return this.instance.post(`/topics`, topic)
  }

  getInstance() {
    return this.instance
  }
  
}

export default new ForumService()