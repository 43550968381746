import React, {useState, useRef} from 'react'

import TopSection from './TopSection'
import SecondSection from './SecondSection'
import BottomSection from './BottomSection'

const About = () => {
	const topRef = useRef(null)
	const sectionOneRef = useRef(null)
	const bottomRef = useRef(null)
	const [pageRefs, setPageRefs] = useState([
		{ id: 0,
			selected: true
		},
		{ id: 1,
			selected: false
		},
		{ id: 2,
			selected: false
		}
	])

	const setNavDots = (refSelected) => {
		let newPageRefs = []
		for(let i=0; i < pageRefs.length; i++) {
			if(i === refSelected) newPageRefs[i] = { id: i, selected: true }
			else newPageRefs[i] = { id: i, selected: false }
		}
		setPageRefs(newPageRefs)
	}

	const doScroll = (ref, refSelected) => {
		ref.current.scrollIntoView({behavior: "smooth"})
		setNavDots(refSelected)
	}

	return (
		<>
			<div className="navigator">
				<p onClick={() => doScroll(topRef, 0) } className={"dot " + (pageRefs[0].selected ? "" : "smalldot")}/>
				<p onClick={() => doScroll(sectionOneRef, 1)} className={"dot " + (pageRefs[1].selected ? "" : "smalldot")}/>
				<p onClick={() => doScroll(bottomRef, 2)} className={"dot " + (pageRefs[2].selected ? "" : "smalldot")}/>
			</div>
			<ul>
				<TopSection forwardedRef={topRef} setNavDots={setNavDots} dot={0} />
				<SecondSection forwardedRef={sectionOneRef} setNavDots={setNavDots} dot={1} />
				<BottomSection forwardedRef={bottomRef} setNavDots={setNavDots} dot={2} />
			</ul>
		</>
	)
}

export default About