import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'

const SecondSection = (props) => {
	const [content, setConent] = useState("")

	const page = (
		<li className='standard' ref={props.forwardedRef} onMouseEnter={() => props.setNavDots(props.dot)} >
			<img alt="sandbar" className="sandbar" src="/images/sandbar-1.jpg" />					
			<img alt="snowboarding" className="snow" src="/images/snow-1.jpg" />
			<img alt="ocean" className="ocean" src="/images/ocean-1.jpg" />
			<div className="bg-image middle-image transition">
				<div className="container center clear z-five">
					<div className="m-0">
						<h3 className="component-content mb-1 pb-1">my stories</h3>
					</div>
					<div className="container">
						<div className="row py-4">
							<div className="col-5">
								<p className="box-shade pb-1 font-weight-bold">Standalone Novel</p>
							</div>
							<div className="col-7 text-left box-shade">
								<p>Some people believe that the nation of Arlest is as perfect as a human society can be. Those people never go hungry.</p>
								<p>Abuse of a mysterious drug is on the rise in the city of Berdonl. When Moiron Sagekin is called upon to unravel those mysteries, her deepest fears seem unavoidable. She wants no part of it, reminded of the judgment and ridicule that excessive attention brought her as an academic prodigy in her youth. Success in the project will likely bring her fame, even more people will know her name, and Moiron Sagekin does not fail in the laboratory.</p>
								<p>As Moiron surges forward with the study, considering ways she might give someone else the credit and attention, her path leads to betrayals and conspiracies deeper than she could have ever predicted. What new laws will arise from her results? Who will suffer, and who will benefit? Treachery seems to arise around every corner.</p>
								<p>Joined along the way by diverse companions including her warrior sister, a kind-hearted homeless man with an enigmatic background, and peculiar creatures from far away lands, Moiron's journey takes her to fantastic places beyond her dreams, and prejudices out of the darkest of nightmares. Is the political system she is part of far more flawed than she was raised to believe?</p>
								<p><i>Fragments of a Moonless Dawn</i> is an unforgettable adventure of mystery, betrayal, and the power of knowledge. Join Moiron on her epic quest of discovery and philanthropy.</p>
								<p>Check back for more info. <Link to="/contact">Get on the mailing list now!</Link></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</li>
	)

	useEffect(() =>{
		const timeout = setTimeout(() => setConent(page), 1500);
    return () => clearTimeout(timeout);
	},[])

	return content
}

export default SecondSection