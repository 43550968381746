import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import Header from './components/ui/Header'
import Home from './components/Home'
import About from './components/About'
import Contact from './components/Contact'
import Teasers from './components/Teasers'
import Secret from './components/Secret'
import TopicList from './components/Forum/TopicList'
import TopicForm from './components/Forum/TopicForm'
import PostList from './components/Forum/PostList'
import PostForm from './components/Forum/PostForm'


import './App.css';

const App = () => {
  return (
    <Router>
      <Header/>
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="/secret" component={Secret} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/teasers" component={Teasers} />
        <Route path="/forum" component={TopicList} />
        <Route path="/post-list" component={PostList} />
        <Route path="/topic-form/:id" component={TopicForm} />
        <Route path="/post-form/:id" component={PostForm} />
        <Route path="/post-form/topic/:topicId" component={PostForm} />
        <Redirect from="/" to="/home" />
      </Switch>
    </Router>
  );
}

export default App;
