import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet'

const Contact = () => {
	const reloadCount = sessionStorage.getItem('reloadCount');

	useEffect(() => {
    if(reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }, []);

	return (
		<>
			<Helmet>
			{/* MailerLite Universal */}
			<script>
			{`
				(function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
				.push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
				n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
				(window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
				ml('account', '1024520');
			`}
			</script>
			{/* End MailerLite Universal */}
			</Helmet>
			<div className="bg-home bg-image" id='home'>
				<div className='py-5' id='contact'></div>
				<h3 className="d-flex justify-content-center magic-text">Contact</h3>
				<hr className='w-75'/>
				<div className='row'>					
					<div className='col-sm-3 col-1'></div>
					<div className='col-sm-6 col-10 px-2'>
						<div className="container my-4 mb-5 py-3 px-1 shade-box">
							
							<div className="ml-embedded" data-form="2qZ85I"></div>
							
						</div>
					</div>
					<div className='col-sm-3 col-1'></div>
				</div>
				<div className='container mx-auto shade-box w-50'>
					<h4 className="d-flex justify-content-center fantasy-text">
						<span className="bottom-text">Follow me:</span>
						<a href='https://www.facebook.com/61556446385647/' target="_blank">
							<span className='px-1'></span>
							<img alt='Facebook' src={require('../images/facebook.png')} style={{height: '39px', width: '39px'}}/>
						</a>
						<a href='https://www.instagram.com/j.a.thurlow/' target="_blank">
							<span className='px-1'></span>
							<img alt='Instagram' src={require('../images/instagram.png')} style={{height: '39px', width: '39px'}}/>
						</a>
					</h4>
				</div>
				
			</div>
		</>
	)
}

export default Contact