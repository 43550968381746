import axios from 'axios'

import { FORUM_API_URL } from '../Constants'

class PostService {
  constructor() {
    this.instance = axios.create({
      baseURL: FORUM_API_URL
    })
    
  }

  // TODO: need a for-real all for admins
  getPostsAll() {
    return this.instance.get(`/posts`)
  }

  getPostsByTopic(topicId) {
    return this.instance.get(`/posts/topic/${topicId}`)
  }

  getPostsByDateRange(startDate, endDate) {
    return this.instance.get(`/posts?starDate=${startDate}&endDate=${endDate}`)
  }

  getPostsByCreatedBy(id) {
    return this.instance.get(`/posts/createdBy/${id}`)
  }

  getPostById(id) {
    return this.instance.get(`/posts/${id}`)
  }

  deletePost(id) {
    return this.instance.delete(`/posts/${id}`)
  }

  createOrUpdatePost(id, post) {
    if(id !== "new") {
      return this.instance.put(`/posts/${id}`, post)
    }
    post.id = 0
    return this.instance.post(`/posts`, post)
  }

  getInstance() {
    return this.instance
  }
  
}

export default new PostService()