import React from 'react'

const TopSection = (props) => {

	return (
		<li ref={props.forwardedRef} onMouseEnter={() => props.setNavDots(props.dot)} className="slideInFirst">
			<div className="head-image img-fluid z-five about-image">
				<div className='row'>					
					<div className='col-sm-4 col-1'></div>
					<div className='col-sm-4 col-10 px-2'>
						<div className="container my-4 mb-5 py-3 px-1 shade-box text-center">
							<h4 className='px-3'>about the author</h4>
							<p className='px-4'>i write books. that is all.</p>
						</div>
					</div>
					<div className='col-sm-4 col-1'></div>
				</div>
			</div>
		</li>
	)
}

export default TopSection