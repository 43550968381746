import React, {useState, useEffect} from 'react'
import HeaderForum from '../ui/HeaderForum'

import ForumService from '../../services/ForumService'
import TopicRow from './TopicRow'

const TopicList = (props) => {
	const [topics, setTopics] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false)
  let topicList = []
  // let recentX = 10

  useEffect(() => {
    if(!topics.length) loadTopics()
  })


  const loadTopics = () => {
    ForumService.getTopicsAll()
    .then(response => {
      setTopics(response.data)
      setLoading(false)
    })
    .catch(e => {
      let fetchError = e.message || e.response.data
      console.log(e)
      setError(
        <div>
          <HeaderForum/>
          <h3 style={{position: 'fixed', top: '162px'}}>{fetchError}</h3>
        </div>
      )
    })
  }

  if(topics.length) {
    topicList = (
      topics.map(topic => (
        <TopicRow topic={topic} key={topic.id} />
      ))
    )
  }
  
  let waitContent = error ? error : 
  <div>
    <HeaderForum/>
    <h3 style={{position: 'fixed', top: '162px'}}>Loading...</h3>
  </div>


// TODO: condition error
	return (error || loading) ? waitContent : (
		<>
			<HeaderForum/>
        <div className='container double-header'>
          <div className="container list-group-item" >
            <div className='row'>
              <div className='col-3'>
                <h4 className="mb-1">Title</h4>
              </div>
              <div className='col-5'>
                <h4 className="mb-1">Description</h4>
              </div>
              <div className='col-2'>
                <h5 className="mb-1">Last Post</h5>
              </div>
              <div className='col-1'>
                <h6>Activity</h6>
              </div>
              <div className='col-1'>
                <p></p>
              </div>
            </div>
            </div>
				<div className='list-group'>
      		{topicList}
				</div>
			</div>
		</>
  )
}

export default TopicList