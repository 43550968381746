import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link';
import Logo from '../Logo'

const Header = () => {
	const history = useHistory();
	const [isSticky, setSticky] = useState(false);
	const [logoClickCount, setLogoClickCount] = useState(0);
	
	const handleScroll = () => {	
		const windowScrollTop = window.scrollY;
	
		if (windowScrollTop > 10) {
			setSticky(true);
		} else {
			setSticky(false);
		}
	};
	
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
 		
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};

	}, []);

	const logoClicked = () => {
		setLogoClickCount(logoClickCount + 1)
		if(logoClickCount >= 3) {
			history.push("/secret");
		}
	}

	return (
		<header style={{ background: isSticky ? '#fff' : '', width: '100%', zIndex: '999', position:isSticky ?'fixed':'absolute' }}>
			<nav className="navbar navbar-expand-md navbar-dark bg-dark justify-content-between py-0">
				<div className="navbar-brand float-right" onClick={logoClicked} ><Logo/></div>
				<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarNav">
					<div className="navbar-nav mx-auto">
						<NavHashLink className="nav-link" to="/home#home">Home</NavHashLink>
						<NavHashLink className="nav-link" to="/home#work">Work</NavHashLink>
						<NavLink className="nav-link" to="/contact">Contact</NavLink>
						<NavLink className="nav-link" to="/about">About</NavLink>
					</div>
				</div>
			</nav>
		</header>
	)
}

export default Header

// import React, { useState, useEffect } from 'react';

// function StickyHeader() {
// 	const [isSticky, setSticky] = useState(false);
	
// 	const handleScroll = () => {
	
// 		const windowScrollTop = window.scrollY;
	
// 		if (windowScrollTop > 10) {
// 			setSticky(true);
// 		} else {
// 			setSticky(false);
// 		}
// 	};
	
// 	useEffect(() => {
// 		window.addEventListener('scroll', handleScroll);
 		
// 		return () => {
// 			window.removeEventListener('scroll', handleScroll);
// 		};

//  }, []);

// const items = [
// 	{
// 		name: 'Home',
// 		link: '/'
// 	},
// 	{
// 		name: 'About',
// 		link: '/about'
// 	},
// 	{
// 		name: 'Contact',
// 		link: '/contact'
// 	}
// ];

// const data = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36 ]

// return (
// 	<div className="App">
// 		<header style={{ background: isSticky ? '#fff' : '', width: '100%', zIndex: '999',position:isSticky ?'fixed':'absolute' }}>
// 			{
// 				items.map(item => (
// 				<a href={item.link} key={item.name}>{item.name}</a>
// 				))
// 			}
// 		</header>
// 		<ul>{data.map((x) => { return (<li key={x}>{x}</li>) } ) }</ul>
// 	</div>
//  );
// }
// export default StickyHeader;