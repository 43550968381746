import React, {useState, useEffect} from 'react'

const BottomSection = (props) => {
	const [content, setConent] = useState("")

	const page = (
		<li ref={props.forwardedRef} onMouseEnter={() => props.setNavDots(props.dot)} >
			<div className="bg-image tail-image transition z-five">						
				<div className="container center clear">
					<div className="m-0">
						<h2 className="component-content">life</h2>
						<h4 className="box-shade pb-1">Wow! I sure was cute!</h4>
					</div>
				</div>
				<img alt="farm1" className="farm-1" src="/images/farm-1.jpg" />
				<img alt="farm2" className="farm-2" src="/images/farm-2.jpg" />
				<img alt="river" className="river-1 m-5" src="/images/river-1.jpg" />
				{/* <div className='row py-4 my-4'>
					<div className='col-sm-1'></div>
					<div className='col-sm-3 py-4'>
						<img alt="farm1" className="farm-1" src="/images/farm-1.jpg" />
					</div>
					<div className='col-sm-3'></div>
					<div className='col-sm-4'>
						<img alt="farm2" className="farm-2" src="/images/farm-2.jpg" />
					</div>
					<div className='col-sm-1'></div>
				</div>
				<div className='row'>
					<img alt="river" className="river-1" src="/images/river-1.jpg" />
				</div> */}
			</div>
		</li>
	)

	useEffect(() =>{
		const timeout = setTimeout(() => setConent(page), 2000);
    return () => clearTimeout(timeout);
	},[])

	return content
}

export default BottomSection